@import url("https://fonts.googleapis.com/css?family=Montserrat:200,500");

:root {
  --color: #ab1f2a;
  --pra-color: #ab1f2a80;
  --clickable-logo: 24px;
  --information-logo: 16px;
  --heading-font-size-1: 72px;
  --heading-font-size-2: 64px;
  --heading-font-size-3: 48px;
  --heading-font-size-4: 36px;
  --heading-font-size-5: 24px;
  --heading-font-size-6: 20px;
  --heading-font-size-7: 16px;
  --heading-font-size-8: 14px;
  --heading-font-size-9: 12px;
  --heading-line-height: 100%;
  --heading-letter-spacing: 0%;
  --heading-font-weight: bold;
  --subHeading-font-size-1: 24px;
  --subHeading-font-size-2: 20px;
  --subHeading-font-size-3: 18px;
  --subHeading-font-size-4: 16px;
  --subHeading-font-size-5: 14px;
  --subHeading-font-size-6: 12px;
  --subHeading-font-size-7: 10px;
  --subHeading-font-size-8: 8px;
  --subHeading-line-height: 140%;
  --subHeading-letter-spacing: 2%;
  --subHeading-font-weight: 500;
  --label-font-size-1: 30px;
  --label-font-size-2: 24px;
  --label-font-size-3: 20px;
  --label-line-height: 32px;
  --label-letter-spacing: 40%;
  --label-font-weight: bold;
  --paragraph-font-size-1: 18px;
  --paragraph-font-size-2: 16px;
  --paragraph-font-size-3: 14px;
  --paragraph-font-size-4: 12px;
  --paragraph-font-size-5: 10px;
  --paragraph-font-size-6: 8px;
  --paragraph-line-height: 140%;
  --paragraph-letter-spacing: 0%;
  --paragraph-font-weight: normal;
  --font-family: "Montserrat";

  --heading-1: var(--heading-font-weight) var(--heading-font-size-1) /
    var(--heading-line-height) var(--font-family);

  --heading-2: var(--heading-font-weight) var(--heading-font-size-2) /
    var(--heading-line-height) var(--font-family);

  --heading-3: var(--heading-font-weight) var(--heading-font-size-3) /
    var(--heading-line-height) var(--font-family);

  --heading-4: var(--heading-font-weight) var(--heading-font-size-4) /
    var(--heading-line-height) var(--font-family);

  --heading-5: var(--heading-font-weight) var(--heading-font-size-5) /
    var(--heading-line-height) var(--font-family);

  --heading-6: var(--heading-font-weight) var(--heading-font-size-6) /
    var(--heading-line-height) var(--font-family);

  --heading-7: var(--heading-font-weight) var(--heading-font-size-7) /
    var(--heading-line-height) var(--font-family);

  --heading-8: var(--heading-font-weight) var(--heading-font-size-8) /
    var(--heading-line-height) var(--font-family);

  --heading-9: var(--heading-font-weight) var(--heading-font-size-9) /
    var(--heading-line-height) var(--font-family);

  --subHeading-1: var(--subHeading-font-weight) var(--subHeading-font-size-1) /
    var(--subHeading-line-height) var(--font-family);

  --subHeading-2: var(--subHeading-font-weight) var(--subHeading-font-size-2) /
    var(--subHeading-line-height) var(--font-family);

  --subHeading-3: var(--subHeading-font-weight) var(--subHeading-font-size-3) /
    var(--subHeading-line-height) var(--font-family);

  --subHeading-4: var(--subHeading-font-weight) var(--subHeading-font-size-4) /
    var(--subHeading-line-height) var(--font-family);

  --subHeading-5: var(--subHeading-font-weight) var(--subHeading-font-size-5) /
    var(--subHeading-line-height) var(--font-family);

  --subHeading-6: var(--subHeading-font-weight) var(--subHeading-font-size-6) /
    var(--subHeading-line-height) var(--font-family);

  --subHeading-7: var(--subHeading-font-weight) var(--subHeading-font-size-7) /
    var(--subHeading-line-height) var(--font-family);

  --subHeading-8: var(--subHeading-font-weight) var(--subHeading-font-size-8) /
    var(--subHeading-line-height) var(--font-family);

  --label-1: var(--label-font-weight) var(--label-font-size-1) /
    var(--label-line-height) var(--font-family);

  --label-2: var(--label-font-weight) var(--label-font-size-2) /
    var(--label-line-height) var(--font-family);

  --label-3: var(--label-font-weight) var(--label-font-size-3) /
    var(--label-line-height) var(--font-family);

  --paragraph-1: var(--paragraph-font-weight) var(--paragraph-font-size-1) /
    var(--paragraph-line-height) var(--font-family);

  --paragraph-2: var(--paragraph-font-weight) var(--paragraph-font-size-2) /
    var(--paragraph-line-height) var(--font-family);

  --paragraph-3: var(--paragraph-font-weight) var(--paragraph-font-size-3) /
    var(--paragraph-line-height) var(--font-family);

  --paragraph-4: var(--paragraph-font-weight) var(--paragraph-font-size-4) /
    var(--paragraph-line-height) var(--font-family);

  --paragraph-5: var(--paragraph-font-weight) var(--paragraph-font-size-5) /
    var(--paragraph-line-height) var(--font-family);

  --paragraph-6: var(--paragraph-font-weight) var(--paragraph-font-size-6) /
    var(--paragraph-line-height) var(--font-family);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  text-decoration: none;
}

span {
  color: #b81f24;
  font-weight: bold;
}

.warning-text {
  color: red;
  font-weight: bold;
}
