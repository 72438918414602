.jobspage-svg {
  height: 50px;
  width: 50px;
  border-radius: 8px;
}

@media only screen and (min-width: 912px) {
  .jobspage-svg {
    height: 80px;
    width: 80px;
  }
}