.custom-button2 {
  display: flex;
  height: 35px;
  width: 100%;
  border-radius: 6px;
	padding: 0px;
	align-items: center;
	justify-content: center;
	font: var(--heading-8);
  font-weight: 500;
}

.custom-button2.transparant {
  background-color: #fff;
	border: 1px solid var(--color);
	color: var(--color);
}

.custom-button2.color {
  background-color: var(--color);
  border: 1px solid #E4E4E7;
	color: #fff;
}

.custom-button2:hover {
  cursor: pointer;
}

.custom-button2.notAllowed {
  background-color: #E4E4E7;
  border: 1px solid #BCBCC2;
  color: #1C1C1C;
}

.custom-button2.notAllowed:hover {
  cursor: not-allowed
}

@media only screen and (min-width: 912px) {
  .custom-button2 {
    font: var(--heading-7);
    font-weight: 500;
    height: 40px;
    width: 200px;
  }
}