.verifikasi-container {
  display: flex;
  margin-top: 50px;
}

.verifikasi-wrap {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 30px 15px;
  max-width: 1200px;
  width: 100%;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.verifikasi-wrap h1 {
  font: var(--heading-5);
}

.verifikasi-wrap h5 {
  font: var(--paragraph-3);
  color: #BCBCC2;
  width: 100%;
  max-width: 500px;
}

.verifikasi-wrap-image {
  position: relative;
  width: 100%;
  height: 250px;
  max-width: 1200px;
}

.verifikasi-wrap-image-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  width: 80vw;
  max-width: 300px;
  height: 250px;
  left: 50%;
  transform: translateX(-50%);
}

.verifikasi-wrap-button {
  display: flex;
  height: 40px;
  width: 100%;
  max-width: 550px;
  background-color: var(--color);
  border-radius: 6px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  font: var(--heading-8);
  font-weight: 500;
  border: 1px solid #E4E4E7;
  color: #fff;
  margin-top: 10px;
}

.verifikasi-wrap-button:hover {
  cursor: pointer;
}

.verifikasi-wrap-button.disable:hover {
  cursor: not-allowed;
}

.verifikasi-count {
  font: var(--heading-8);
  margin-top: -10px;
}

@media only screen and (min-width: 912px) {
  .verifikasi-container {
    margin-top: 80px;
  }

  .verifikasi-wrap {
    padding: 30px 40px;
    gap: 20px;
  }

  .verifikasi-wrap h1 {
    font: var(--heading-4);
  }
  
  .verifikasi-wrap h5 {
    font: var(--paragraph-2);
  }

  .verifikasi-wrap-button {
    font: var(--heading-7);
    font-weight: 500;
    height: 45px;
  }

  .verifikasi-count {
    font: var(--heading-7);
    margin-top: -15px;
  }
}

@media only screen and (min-width: 1200px) {
  .verifikasi-wrap {
    padding: 0px;
    margin: 30px auto;
  }
}