.pengaturan-detail-top {
  justify-content: space-between;
}

.pengaturan-detail-top-title {
  margin: 0px 0px 5px;
}

label.pengaturan-detail-top-title-label {
  font: var(--subHeading-4);
}

.pengaturan-detail-minat {
  margin-bottom: 5px;
}

.pengaturan-detail-minat-button {
  font: var(--subHeading-6)
}

.pengaturan-detail-minat-button.editable {
  background-color: #E4E4E7;
  box-shadow: 0 ;
}

.pengaturan-detail-minat-button.editable:hover{
  cursor: not-allowed;
  border: 1px solid #1C1C1C;
	color: #1C1C1C;
}

.pengaturan-detail-minat-button.editable.clicked{
  color: #B4B4B7;
  border: 1px solid #B4B4B7;
}

.pengaturan-detail-minat-button.editable.clicked:hover{
  color: #1C1C1C;
  border: 1px solid #1C1C1C;
}

.pengaturan-detail-minat-subKategori {
  margin-top: 5px;
}

label.pengaturan-detail-minat-subKategori-label {
  font: var(--subHeading-5)
}

label.pengaturan-detail-minat-subKategori-wrap-label {
  font: var(--subHeading-7)
}

@media only screen and (min-width: 912px) {
  label.pengaturan-detail-top-title-label {
    font: var(--subHeading-3);
  }

  .pengaturan-detail-minat {
    margin-bottom: 10px;
  }

  .pengaturan-detail-minat-button {
    font: var(--subHeading-5)
  }

  .pengaturan-detail-minat-subKategori {
    margin-top: 10px;
  }

  label.pengaturan-detail-minat-subKategori-label {
    font: var(--subHeading-4)
  }

  label.pengaturan-detail-minat-subKategori-wrap-label {
    font: var(--subHeading-6)
  }
}