.dompetSaya-saldo {
  display: flex;
  gap: 15px;
}

.dompetSaya-saldo-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7F7F7;
  padding: 7px;
  border-radius: 8px;
  border: 1px solid #E4E4E7;
}

.dompetSaya-saldo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dompetSaya-saldo-content h5 {
  color: #76767F;
  font: var(--subHeading-5);
}

.dompetSaya-saldo-content h3 {
  color: #1C1C1C;
  font: var(--subHeading-3);
}

.dompetSaya-saldo-button, .dompetSaya-kartu-button {
  display: flex;
}

.dompetSaya-saldo-button-item, .dompetSaya-kartu-button-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  background-color: var(--color);
  color: #fff;
  font: var(--subHeading-5);
  border: 1px solid var(--color);
  border-radius: 20px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.dompetSaya-saldo-button-item:hover, .dompetSaya-kartu-button-item:hover {
  cursor: pointer;
  color: var(--color);
  background-color: #fff;
}

.dompetSaya-kartu-riwayat {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.dompetSaya-kartu-riwayat h5 {
  color: #1C1C1C;
  font: var(--heading-8);
}

.dompetSaya-kartu-button-item {
  border-radius: 10px;
  margin-bottom: 0px;
}

.dompoetSaya-kartu-svg {
  height: 20px;
  width: 20px;
}

.dompetSaya-kartu-riwayat-table {
  width: 100%;
  overflow-x: auto;
}

.dompetSaya-kartu-riwayat-table-wrap {
  width: 100%;
  border-collapse: collapse;
}

.dompetSaya-kartu-riwayat-table-wrap th,
.dompetSaya-kartu-riwayat-table-wrap td {
  padding: 8px;
  text-align: left;
  white-space: nowrap;
}

.dompetSaya-kartu-riwayat-table-wrap th {
  background-color: #f2f2f2;
  font: var(--heading-8);
}

.dompetSaya-kartu-riwayat-table-wrap td {
  font: var(--subHeading-5);
}

.dompetSaya-kartu-riwayat-table-wrap th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.dompetSaya-kartu-riwayat-table-wrap th:last-child {
  border-radius: 0px 10px 10px 0px;
}

.dompetSaya-kartu-table-wrap-status {
  background-color: #DCFFDB;
  color: #04C700;
  padding: 5px;
  border-radius: 20px;
  width: max-content;
}

.dompetSaya-riwayat-table-wrap-status, .dompetSaya-tagihan-table-wrap-status {
  padding: 5px;
  border-radius: 20px;
  width: max-content;
}

.dompetSaya-riwayat-table-wrap-status.income {
  background-color: #E6F8F8;
  color: #AB1F2A;
}

.dompetSaya-riwayat-table-wrap-status.outcome {
  background-color: #E4E4E7;
  color: #76767F;
}

.dompetSaya-tagihan-table-wrap-status.dibayar {
  background-color: #DCFFDB;
  color: #04C700;
}

.dompetSaya-tagihan-table-wrap-status.belumDibayar {
  background-color: #FFFACF;
  color: #D1BD00;
}

.dompetSaya-kartu-table-svg:hover {
  cursor: pointer;
}

.dompetSaya-kartu-riwayat-table-noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #BCBCC2;
  font: var(--subHeading-5);
}

.dompetSaya-kartu-riwayat-table-noData-svg {
  height: 30px;
  width: 30px;
}

@media only screen and (min-width: 912px) {
  .dompetSaya-saldo-svg-item {
    height: 34px;
    width: 34px;
  }

  .dompetSaya-saldo-content h5 {
    font: var(--subHeading-3);
  }
  
  .dompetSaya-saldo-content h3 {
    font: var(--subHeading-2);
  }

  .dompetSaya-saldo-button {
    justify-content: right;
  }

  .dompetSaya-kartu-button {
    justify-content: center;
  }

  .dompetSaya-saldo-button-item, .dompetSaya-kartu-button-item {
    font: var(--subHeading-4);
    width: 300px;
  }

  .dompetSaya-kartu-riwayat h5 {
    font: var(--heading-7);
  }

  .dompoetSaya-kartu-svg {
    height: 24px;
    width: 24px;
  }

  .dompetSaya-kartu-riwayat-table-wrap th {
    font: var(--heading-7);
  }
  
  .dompetSaya-kartu-riwayat-table-wrap td {
    font: var(--subHeading-4);
  }

  .dompetSaya-kartu-riwayat-table-noData {
    font: var(--subHeading-4);
  }

  .dompetSaya-kartu-riwayat-table-noData-svg {
    height: 36px;
    width: 36px;
  }
}