.dashboard-moderator-svg, .dashboard-moderator-button-svg{
  height: 18px;
  width: 18px;
}

.dashboard-moderator-svg:hover{
  cursor: pointer;
  background-color: #E6F8F8;
  border-radius: 8px;
}

.dashboard-moderator-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

@media only screen and (min-width: 912px) {
  .dashboard-moderator-svg, .dashboard-moderator-button-svg{
    height: 22px;
    width: 22px;
  }
}