.Login-wrap-form-section-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.Login-wrap-form-section-buttom-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Login-wrap-form-section-bottom label {
  font: var(--subHeading-5);
}

@media only screen and (min-width: 912px) {
  .Login-wrap-form-section-bottom label {
    font: var(--paragraph-1);
  }
}

@media only screen and (min-width: 1200px) {
  #login-wrap-form {
    padding-right: 0px;
    padding-left: 20px;
  }
}