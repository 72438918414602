.detailChat-container {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.detailChat-wrap {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.detailChat-top {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  background-color: #E4E4E7;
}

.detailChat-top-item {
  display: flex;
  margin: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.detailChat-top-item-detail {
  display: flex;
  align-items: center;
  gap: 15px;
}

.detailChat-top-item-detail-svg:hover {
  cursor: pointer;
}

.detailChat-top-item-detail img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #B0B0B0;
}

.detailChat-top-item-title {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.detailChat-top-item-subtitle {
  display: flex;
  gap: 5px;
  align-items: center;
}

.detailChat-top-item-circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #04C700;
}

.detailChat-top-item-title h4 {
  font: var(--subHeading-5);
  font-weight: bold;
}

.detailChat-top-item-title h5 {
  font: var(--subHeading-7);
}

.detailChat-top-item-blank {
  height: 45px;
}

.detailChat-body {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 225px);
  gap: 10px;
  margin: 15px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.detailChat-body.file{
  max-height: calc(100vh - 265px);
}

.detailChat-body-item {
  display: flex;
}

.detailChat-body-item.pengirim {
  justify-content: right;
}

.detailChat-img {
  display: flex;
  background-color: #E4E4E7;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid #ccc;
  flex: 0 0 auto;
}

.detailChat-img-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 15px;
  width: 100%;
}

.detailChat-img-item img {
  height: 30px;
  width: 30px;
}

.detailChat-img-item video {
  height: 20px;
  width: 20px;
}

.close-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: #ff4d4d;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.close-button-x {
  position: absolute;
  width: 2px;
  height: 8px;
  background-color: white;
}

.close-button-x:first-child {
  transform: rotate(45deg);
}

.close-button-x:last-child {
  transform: rotate(-45deg);
}

.detailChat-bottom {
  display: flex;
  width: 100%;
  background-color: #E4E4E7;
  flex: 0 0 auto;
}

.detailChat-bottom-item {
  display: flex;
  margin: 15px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.detailChat-bottom-item-detail {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.detailChat-bottom-item-detail-box {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
}

.detailChat-bottom-item-detail-box-svg:hover {
  cursor: pointer;
}

.detailChat-bottom-item-detail-box-divider {
  width: 1px;
  height: 30px;
  background-color: #D1D1D1;
  margin: 0px 10px;
  font-size: 2px;
  color: #D1D1D1;
}

.detailChat-bottom-item-detail-box input {
  border: none;
  outline: none;
  border-radius: 8px;
  width: 100%;
  height: 30px;
  font: var(--subHeading-6);
}

.detailChat-bottom-item-detail-send {
  display: flex;
  background-color: #AB1F2A;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
}

.detailChat-bottom-item-detail-send:hover {
  cursor: pointer;
}

.detailChat-bottom-item-detail-send-svg {
  height: 16px;
  width: 16px;
}

@media only screen and (min-width: 912px) {
  .detailChat-container {
    position: static;
    width: auto;
    flex: 3;
    border-radius: 0 8px 8px 0;
    border-left: 1px solid #E4E4E7;
  }

  .detailChat-top {
    border-radius: 0 8px 0 0;
  }

  .detailChat-body {
    max-height: calc(100vh - 350px);
  }

  .detailChat-notSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .detailChat-notSelected p {
    font: var(--paragraph-4);
    background-color: #ccc;
    padding: 2px;
    border-radius: 4px;
  }
}