.kategori-container {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.kategori-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  margin: 0px auto;
  max-width: 1200px;
  width: 100%;
}

.kategori-wrap h3 {
  margin: 30px 0px 10px;
  font: var(--label-3);
  letter-spacing: 10px;
  color: #b81f24;
}

.kategori-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.kategori-item-svg:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .kategori-wrap {
    padding: 0px 40px;
  }

  .kategori-wrap h3 {
    margin-top: 80px;
    font: var(--label-1);
  }
}

@media only screen and (min-width: 1200px) {
  .kategori-container {
    margin-bottom: 70px;
  }

  .kategori-wrap {
    padding: 0px;
  }
}
