.footer-container {
  display: flex;
  flex-direction: column;
  background-color: #E4E4E7;
}

.footer-wrap-top {
  background-color: #fff;
}

.footer-wrap-bottom {
  margin: 0px;
  max-width: 100%;
}

.footer-wrap-top, .footer-wrap-bottom {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
}

.footer-wrap-dropdown {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.footer-wrap-dropdown:hover {
  cursor: pointer;
}

.footer-wrap-dropdown-cara {
  border-top: 1px solid #ccc;
}

.footer-wrap-dropdown-detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 15px;
  margin-bottom: 10px;
  transition: all 1s ease;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  font: var(--subHeading-7);
  color: #55555E;
}

.footer-wrap-dropdown-detail.show {
  max-height: 500px;
  opacity: 1;
  visibility: visible;
}

.footer-wrap-bottom-detail {
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
  gap: 20px;
  max-width: 1200px;
}

.footer-wrap-bottom-detail-slice, .footer-wrap-bottom-detail-slice-item-secure {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font: var(--subHeading-7);
  color: #55555E;
}

.footer-wrap-bottom-detail-slice-title {
  font: var(--heading-9);
  color: #AB1F2A; 
}

.footer-wrap-bottom-detail-slice-logo {
  display: flex;
  gap: 15px;
}

.footer-wrap-bottom-detail-slice-logo-svg, .footer-wrap-bottom-detail-slice-logo-img {
  width: auto;
  height: 30px;
}

.footer-wrap-bottom-detail-slice-share {
  display: flex;
  gap: 10px;
}

.footer-wrap-bottom-detail-slice-share-svg{
  width: 24px;
  height: 24px;
}

.footer-wrap-bottom-detail-slice-share-svg:hover{
  cursor: pointer;
}

.footer-wrap-bottom-detail-slice-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-wrap-bottom-detail-slice-item-svg {
  height: 20px;
  width: 20px;
}

.footer-wrap-bottom-detail-slice-item-secure {
  flex-direction: row;
}

.footer-wrap-bottom-detail-slice-item-secure-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px;
}

.footer-wrap-copyright {
  display: flex;
  justify-content: center;
  text-align: center;
  border-top: 1px solid #ccc;
  margin: 0px 15px;
  padding: 10px 0px;
  font: var(--subHeading-7);
  font-weight: bold;
  max-width: 1200px;
}

@media only screen and (min-width: 912px) {
  .footer-wrap-bottom{
    padding: 0px;
    margin: 0px auto;
  }

  .footer-wrap-bottom-detail {
    flex-direction: row;
    padding: 25px 40px;
    justify-content: center;
    gap: 45px;
  }

  .footer-wrap-copyright {
    margin: 0px 50px;
    font: var(--heading-8);
  }

  .footer-wrap-bottom-detail-slice {
    flex: 1;
  }

  .footer-wrap-bottom-detail-slice-title {
    font: var(--heading-7);
  }

  .footer-wrap-top, .footer-wrap-bottom, .footer-wrap-bottom-detail-slice, .footer-wrap-bottom-detail-slice-item-secure {
    font: var(--subHeading-6);
  }

  .footer-wrap-bottom-detail-slice-logo-svg, .footer-wrap-bottom-detail-slice-logo-img {
    height: 35px;
  }
  
  .footer-wrap-bottom-detail-slice-share-svg{
    width: 36px;
    height: 36px;
  }

  .footer-wrap-bottom-detail-slice-item-svg {
    height: 30px;
    width: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .footer-wrap-bottom-detail {
    padding: 25px 0px;
  }
}