.detailChatCard-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: fit-content;
  max-width: 80%;
  background-color: #E6F8F8;
}

.detailChatCard-container.pengirim {
  background-color: #E4E4E7;
}

.detailChatCard-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px;
}

.detailChatCard-wrap-dokumen {
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.detailChatCard-wrap-dokumen button {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 10px;
}

.detailChatCard-wrap-dokumen button:hover {
  cursor: pointer;
  background-color: #76767F;
}

.detailChatCard-wrap-dokumen button a {
  text-decoration: none;
  color: inherit;
}

.detailChatCard-wrap-content {
  font: var(--paragraph-3);
  overflow: hidden;
  overflow-wrap: break-word;
}

.detailChatCard-wrap-time {
  display: flex;
  justify-content: right;
}

.detailChatCard-wrap-time p {
  font: var(--paragraph-5);
  color: #76767F;
}