.custom-button3 {
  display: flex;
  height: 35px;
  width: 100%;
  border-radius: 6px;
	padding: 0px;
	align-items: center;
	justify-content: center;
	font: var(--heading-8);
  font-weight: 500;
  gap: 5px;
}

.custom-button3.grey {
  background-color: #E4E4E7;
  border: 1px solid #BCBCC2;
  color: #1C1C1C;
}

.custom-button3.color {
  background-color: var(--color);
  border: 1px solid #E4E4E7;
	color: #fff;
}

.custom-button3:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .custom-button3 {
    font: var(--heading-7);
    font-weight: 500;
    height: 40px;
  }
}