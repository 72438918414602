.minat-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.minat-item-button {
	border-radius: 8px;
	padding: 10px 25px;
	align-items: center;
	justify-content: center;
	font: var(--subHeading-5);
	background-color: #fff;
	border: 1px solid #1C1C1C;
	color: #1C1C1C;
	transition: all 0.5s ease;
}

.minat-item-button.clicked {
  background-color: var(--color);
  border: 1px solid var(--color);
  color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.minat-item-button:hover{
  cursor: pointer;
	background-color: var(--pra-color);
  border: 1px solid var(--pra-color);
	color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.minat-subKategori {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.minat-subKategori-wrap {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #E4E4E7;
  border-radius: 6px;
  gap: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.minat-subKategori-wrap label {
  font: var(--subHeading-4);
  align-self: center;
}

.minat-subKategori-wrap-item {
  display: flex;
  justify-content: space-between;
}

.minat-subKategori-wrap-item label {
  font: var(--subHeading-6);
}

@media only screen and (min-width: 912px) {
  .minat-item {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }

  .minat-item-button {
    font: var(--subHeading-4);
  }

  .minat-subKategori {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .minat-subKategori-wrap {
    padding: 20px 40px;
  }

  .minat-subKategori-wrap label {
    font: var(--subHeading-3);
    align-self: center;
  }
  
  .minat-subKategori-wrap-item label {
    font: var(--subHeading-5);
  }
}