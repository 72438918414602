.pengalamanPendidikan-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}

.pengalamanPendidikan-checkbox {
  margin-bottom: 20px;
}

.pengalamanPendidikan-checkbox-real {
  display: flex;
  gap: 10px;
}

.pengalamanPendidikan-checkbox-real label {
  font: var(--subHeading-4);
}

.pengelamanPendidikan-svg {
  display: flex;
  flex: 1;
  height: auto;
  justify-content: flex-end;
  align-items: center;
}

.pengelamanPendidikan-svg-item {
  height: 22px;
  width: 22px;
}

.pengelamanPendidikan-svg-item:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .pengalamanPendidikan-checkbox {
    display: flex;
  }

  .pengalamanPendidikan-checkbox-ghost {
    flex: 1;
  }

  .pengalamanPendidikan-checkbox-real {
    flex: 2;
  }

  .pengelamanPendidikan-svg-item {
    height: 24px;
    width: 24px;
  }
}