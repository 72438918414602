.informasiProfilIdentitas-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}

.informasiProfilIdentitas-item-foto {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.informasiProfilIdentitas-item-foto-label {
  display: flex;
  width: 100%;
}

.informasiProfilIdentitas-item-foto-img {
  display: flex;
  gap: 20px;
}

.informasiProfilIdentitas-item-foto-title{
  font: var(--subHeading-4);
}

.informasiProfilIdentitas-item-foto-label-warning {
  font: var(--subHeading-6);
}

.informasiProfilIdentitas-item-foto-img img{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #B0B0B0;
}

.informasiProfilIdentitas-item-foto-img p{
  font: var(--subHeading-7);
  height: fit-content;
  padding: 2px;
  border-radius: 8px;
  color: #fff;
}

.informasiProfilIdentitas-item-foto img:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .informasiProfilIdentitas-item-foto {
    flex-direction: row;
    align-items: center;
  }

  .informasiProfilIdentitas-item-foto-label {
    flex: 1;
  }
  
  .informasiProfilIdentitas-item-foto-img {
    flex: 2;
  }

  .informasiProfilIdentitas-item-foto-title{
    font: var(--subHeading-3);
  }

  .informasiProfilIdentitas-item-foto-label-warning {
    font: var(--subHeading-5);
  }

  .informasiProfilIdentitas-item-foto-img img{
    width: 100px;
    height: 100px;
  }

  .informasiProfilIdentitas-item-foto-img p{
    font: var(--subHeading-6);
    border-radius: 10px;
  }
}