.custom-input-image-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-input-image-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 6px;
}

.custom-input-image-input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  background-image: 
    linear-gradient(to right, #E4E4E7 50%, rgba(255,255,255,0) 0%),
    linear-gradient(to right, #E4E4E7 50%, rgba(255,255,255,0) 0%),
    linear-gradient(to bottom, #E4E4E7 50%, rgba(255,255,255,0) 0%),
    linear-gradient(to bottom, #E4E4E7 50%, rgba(255,255,255,0) 0%);
  background-position: bottom, top, left, right;
  background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  border-radius: 6px;
  font: var(--subHeading-5);
  gap: 5px;
}

.custom-input-image-input:hover {
  cursor: pointer;
}

.custom-input-image-input img{
  max-height: calc(100% - 10px);
  max-width: calc(100% - 10px);
}

@media only screen and (min-width: 912px) {
  .custom-input-image-container.noRegisLogin {
    flex-direction: row;
  }

  .custom-input-label.noRegisLogin {
    flex: 1;
  }

  .custom-input-image-wrap.noRegisLogin {
    flex: 2;
  }
}