.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.pagination-page {
  display: flex;
  justify-content: center;
  width: 10px;
  font: var(--paragraph-3);
}

.pagination-page:hover {
  cursor: pointer;
}

.pagination-page.clicked {
  border-bottom: 1px solid #1C1C1C;
}

.pagination-svg:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .pagination-wrap {
    gap: 20px;
  }

  .pagination-page {
    width: 15px;
    font: var(--paragraph-2);
  }
}