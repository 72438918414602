.ratingCard-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ratingCard-profile {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #E4E4E7;
}

.ratingCard-header {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ratingCard-name-item {
  font: var(--heading-7);
}

.ratingCard-kategori {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding-bottom: 10px;
}

.ratingCard-kategori-detail {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  background-color: #E6F8F8;
  color: #AB1F2A;
  border-radius: 12px;
  font: var(--subHeading-6);
}

.ratingCard-name {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.ratingCard-star-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratingCard-star-rating-label {
  font: var(--subHeading-5);
}

.star-rating {
	white-space: nowrap;
}
.star-rating [type="radio"] {
	appearance: none;
}

.star-rating label {
  margin-right: 5px;
  cursor: pointer;
  font-size: 16px;
}

.star-rating label:hover i,
.star-rating input[type="radio"]:checked ~ label i {
  transform: scale(1.35);
  color: #fffdba;
  animation: jump 0.5s calc(0.3s + (var(--i) - 1) * 0.15s) alternate infinite;
}

.star-rating input[type="radio"]:checked ~ label i {
  color: #faec1b;
  text-shadow: 0 0 2px #ffffff, 0 0 10px #ffee58;
}

@keyframes jump {
  0%, 50% {
    transform: translateY(0) scale(1.35);
  }
  100% {
    transform: translateY(-15%) scale(1.35);
  }
}

@media only screen and (min-width: 912px) {
  .ratingCard-name-item {
    font: var(--heading-6);
  }

  .star-rating label {
    margin-right: 10px;
    font-size: 24px;
  }

  .ratingCard-kategori-detail {
    font: var(--subHeading-5);
  }

  .ratingCard-star-rating-label {
    font: var(--subHeading-4);
  }
}