.custom-button4 {
  display: flex;
  flex: 1;
  height: 35px;
  border-radius: 6px;
	padding: 0px 10px;
	align-items: center;
	justify-content: center;
	font: var(--heading-8);
  font-weight: 500;
}

.custom-button4.transparant {
  background-color: #fff;
	border: 1px solid var(--color);
	color: var(--color);
}

.custom-button4.color {
  background-color: var(--color);
  border: 1px solid #E4E4E7;
	color: #fff;
}

.custom-button4.notAllowed {
  background-color: transparent;
  color: #AD1F2A;
  border: none;
}

.custom-button4:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .custom-button4 {
    font: var(--heading-7);
    font-weight: 500;
    height: 40px;
    padding: 0px 20px;
    flex: none;
  }
}