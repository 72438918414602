.navbar-container {
  display: flex;
  position: fixed;
  height: 50px;
  top: 0px;
  width: 100vw;
  left: 0px;
  z-index: 3;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

.navbar-wrap {
  display: flex;
  margin: 0px auto;
  padding: 10px 15px;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.navbar-wrap-left {
  display: flex;
  flex: 1;
}

.navbar-logo-spageti {
  height: 24px;
  width: 24px;
}

.navbar-logo-spageti:hover {
  cursor: pointer;
}

.navbar-fakta {
  width: auto;
  height: 16px;
}

.navbar-wrap-right {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 20px;
  flex: 1;
}

.navbar-wrap-right-noLogin {
  display: flex;
  align-items: center;
  gap: 5px;
}

.navbar-wrap-right img,
.registerLoginModal-button img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #b0b0b0;
}

.navbar-wrap-right img:hover {
  cursor: pointer;
}

.navbar-wrap-right-detail {
  width: 25px;
  height: 25px;
}

.navbar-wrap-celnter-detai {
  position: relative;
}

.navbar-wrap-center-detail::after {
  display: block;
  content: "";
  border-bottom: solid 3px #b81f24;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navbar-wrap-center-detail:hover::after {
  transform: scaleX(1);
}

.active::after {
  transform: scaleX(1);
}

.navbar-wrap-right-detail:hover {
  cursor: pointer;
}

.registerLoginModal-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.registerLoginModal-lineBreak {
  border-bottom: 1px solid #ccc;
}

.registerLoginModal-wrap button {
  display: flex;
  width: 100%;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  font: var(--subHeading-6);
  background-color: #fff;
  border: 1px solid var(--color);
  color: var(--color);
  padding: 2px;
}

.registerLoginModal-wrap button:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .navbar-container {
    height: 70px;
    width: calc(100vw - 17px);
  }

  .navbar-wrap {
    justify-content: space-between;
    padding: 20px 40px;
  }

  .navbar-fakta {
    width: auto;
    height: 24px;
  }

  .navbar-wrap-center {
    display: flex;
    gap: 30px;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .navbar-wrap-center-detail {
    font: var(--subHeading-2);
    color: #000;
  }

  .navbar-item-search {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 35px;
  }

  .navbar-item-search input {
    border: none;
    outline: none;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    font: var(--heading-8);
    font-weight: 500;
  }

  .navbar-logo-search {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }

  .navbar-logo-search {
    cursor: pointer;
  }

  .navbar-wrap-right {
    gap: 30px;
  }

  .navbar-wrap-right img,
  .registerLoginModal-button img {
    width: 25px;
    height: 25px;
  }

  .navbar-wrap-right-detail {
    width: 30px;
    height: 30px;
  }

  .navbar-button-client,
  .navbar-button-freelance {
    display: flex;
    margin-left: 20px;
    height: 35px;
    width: 130px;
    border-radius: 8px;
    padding: 0px 15px;
    align-items: center;
    justify-content: center;
    font: var(--heading-8);
    font-weight: 500;
  }

  .navbar-button-client:hover,
  .navbar-button-freelance:hover {
    cursor: pointer;
  }

  .navbar-button-client {
    background-color: #ab1f2a;
    border: 1px solid #fff;
    color: #fff;
  }

  .navbar-button-freelance {
    background-color: #fff;
    border: 1px solid #222222;
    color: #222222;
  }

  .navbar-logo-client,
  .navbar-logo-freelance {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .registerLoginModal-wrap button {
    font: var(--subHeading-5);
  }
}

@media only screen and (min-width: 1200px) {
  .navbar-wrap {
    padding: 20px 0px;
  }
}
