.custom-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-input-label {
  display: flex;
  align-items: center;
}

.custom-input-label-title {
  font: var(--subHeading-4);
}

.custom-input-label-warning {
  font: var(--subHeading-6);
}

.custom-input-item {
  display: flex;
  gap: 30px;
}

.custom-input-item.non-radio {
  flex-direction: column;
  gap: 5px;
}

.custom-input-input {
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px;
  background-color: #fff;
}

.custom-input-input-radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom-input-input.textarea {
  flex-direction: column;
}

.custom-input-input input, .custom-input-input textarea {
  border: none;
  outline: none;
  border-radius: 6px;
  font: var(--subHeading-5);
}

.custom-input-input input  {
  width: 100%;
}

.custom-input-label-warning.custom-input-input-label{
  text-align: justify;
}

.custom-input-input-number{
  padding: 0px 5px;
  font: var(--subHeading-5);
}

.custom-input-input-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.custom-input-label-detail {
  display: flex;
  justify-content: space-between;
}

.custom-input-label-warning.detail-text {
  color: #BCBCC2;
}

@media only screen and (min-width: 912px) {
  .custom-input-wrap.noRegisLogin {
    flex-direction: row;
  }

  .custom-input-label.noRegisLogin {
    flex: 1;
  }

  .custom-input-item.noRegisLogin {
    flex: 2;
  }

  .custom-input-label-title {
    font: var(--subHeading-3);
  }
  
  .custom-input-label-warning {
    font: var(--subHeading-5);
  }

  .custom-input-input input, 
  .custom-input-input-number,
  .custom-input-input textarea {
    font: var(--subHeading-4);
  }
  
  .custom-input-input-number {
    padding: 5px;
  }

  .custom-input-input input, .custom-input-input textarea {
    padding: 5px;
  }
}