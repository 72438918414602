.custom-button {
	display: flex;
	height: auto;
	width: 100%;
	max-width: 180px;
	border-radius: 8px;
	padding: 8px;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border: 2px solid #E4E4E7;
	color: #1C1C1C;
	font: var(--heading-8);
	letter-spacing: var(--heading-letter-spacing);
	transition: background-color 0.5s ease;
}

.custom-button.fullWidth {
	max-width: none;
}

.custom-button:hover {
	cursor: pointer;
	background-color: #AB1F2A;
	border: 2px solid #fff;
	color: #fff;
}

@media only screen and (min-width: 912px) {
	.custom-button {
		padding: 10px;
		max-width: 220px;
		font: var(--heading-7);
	}

	.custom-button.fullWidth {
		max-width: 220px;
	}
}