.registerLoginModal-button {
  display: flex;
  font: var(--subHeading-6);
  color: #000;
  padding: 5px 0px;
  align-items: center;
  gap: 10px;
}

.registerLoginModal-button:hover {
  background-color: #E6F8F8;
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .registerLoginModal-button {
    font: var(--subHeading-5);
  }
}