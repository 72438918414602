.detailJobs-container {
  display: flex;
  margin-top: 50px;
  overflow-x: hidden;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.detailJobs-wrap {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 10px 15px;
  width: 100%;
}

.detailJobs-top, .detailJobs-center , .detailJobs-bottom  {
  display: flex;
  background-color: #fff;
  width: 100%;
}

.detailJobs-top {
  border-radius: 8px 8px 0px 0px;
}

.detailJobs-center {
  border-bottom: 1px solid #ccc;
}

.detailJobs-center-wrap, .detailJobs-bottom-wrap {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  gap: 10px;
  width: 100%;
}

.detailJobs-center-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 900px;
  overflow-wrap: break-word;
  width: 100%;
}

.detailJobs-center-item h3 {
  font: var(--subHeading-5);
  font-weight: bold;
}

.detailJobs-center-item p {
  font: var(--paragraph-4);
  white-space: normal;
}

.detailJobs-bottom {
  border-radius: 0px 0px 8px 8px;
}

.detailJobs-bottom-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.detailJobs-bottom-price label {
  font: var(--subHeading-3);
}

.detailJobs-addons {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.detailJobs-addons-bar {
  display: flex;
  gap: 30px;
  font: var(--subHeading-4);
  color: #BCBCC2;
}

.detailJobs-addons-pelamar, .detailJobs-addons-direkrut {
  padding: 5px;
}

.detailJobs-addons-pelamar:hover, .detailJobs-addons-direkrut:hover {
  cursor: pointer;
}

.detailJobs-addons-pelamar.clicked, .detailJobs-addons-direkrut.clicked {
  color: #1C1C1C;
  border-bottom: 1px solid #BCBCC2;
}

@media only screen and (min-width: 912px) {
  .detailJobs-container {
    margin-top: 70px;
  }

  .detailJobs-wrap {
    padding: 30px 40px;
  }

  .detailJobs-center-wrap {
    padding: 20px;
    gap: 20px;
  }

  .detailJobs-center-item h3 {
    font: var(--subHeading-3);
    font-weight: bold;
  }
  
  .detailJobs-center-item p, .detailJobs-top-detail-bottom-item p {
    font: var(--paragraph-2);
    white-space: pre-line;
  }

  .detailJobs-top-detail-bottom {
    display: flex;
    gap: 40px; 
  }

  .detailJobs-top-detail-bottom-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .detailJobs-bottom-wrap {
    padding: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .detailJobs-bottom-price {
    flex-direction: row;
    gap: 20px;
  }
  
  .detailJobs-bottom-price label {
    font: var(--subHeading-2);
  }
  
  .detailJobs-addons-bar {
    font: var(--subHeading-3);
  }
}

@media only screen and (min-width: 1200px) {
  .detailJobs-wrap {
    margin: 30px auto;
    padding: 0px;
  }
}