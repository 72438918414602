.navigasi-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.navigasi-title {
  display: flex;
  padding: 15px 10px;
  border-radius: 8px 8px 0px 0px;
  background-color: #1C1C1C;
  color: #fff;
  font: var(--heading-7);
}

.navigasi-subTitle {
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: #fff;
  color: #1C1C1C;
  border-bottom: 1px solid #E4E4E7;
  font: var(--subHeading-4);
  gap: 10px;
}

.navigasi-subTitle:last-child {
  border-bottom: none;
  border-radius: 0px 0px 8px 8px;
}

.navigasi-subTitle:hover {
  cursor: pointer;
  background-color: #D3D3D3;
}

.navigasi-subTitle.clicked {
  background-color: #E6F8F8;
}

.navigasi-subTitle.clicked:hover {
  cursor: pointer;
  background-color: #D3D3D3;
}