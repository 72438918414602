.rekapAdmin {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 15px;
}

.rekapAdmin-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  gap: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.rekapAdmin-detail h3 {
  font: var(--heading-7);
}

.rekapAdmin-detail label {
  font: var(--paragraph-4);
  color: #76767F
}

@media only screen and (min-width: 912px) {
  .rekapAdmin-detail h3 {
    font: var(--heading-6);
  }
  
  .rekapAdmin-detail label {
    font: var(--paragraph-3);
  }
}