.custom-input-select-container {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.custom-input-select-wrap {
  display: flex;
  gap: 15px;
  border-radius: 6px;
}

.custom-input-sublabel-title {
  font: var(--subHeading-5);
}

.custom-input-select-item{
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px 5px;
}

.custom-input-select-item:hover {
  cursor: pointer;
}

.custom-input-select-item select {
  border: none;
  outline: none;
  flex: 1;
  border-radius: 6px;
  font: var(--subHeading-5);
}

.custom-input-select-item select:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .custom-input-image-container.noRegisLogin {
    flex-direction: row;
  }

  .custom-input-select-wrap.noRegisLogin {
    flex: 2;
  }

  .custom-input-sublabel-title {
    font: var(--subHeading-4);
  }

  .custom-input-select-item select {
    font: var(--subHeading-4);
  }
}