.author-card-author {
  display: flex;
  gap: 10px;
  background-color: #fff;
}

.author-card-author:hover {
  cursor: pointer;
}

.author-card-author:hover label{
  cursor: pointer;
}

.author-card-author img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #B0B0B0;
}

.author-card-author-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.author-card-author-nama{
  font: var(--subHeading-5);
  color: #1C1C1C;
}

.author-card-author-detail-item {
  display: flex;
  font: var(--subHeading-6);
  gap: 5px;
  align-items: center;
}

.author-card-author-detail-item.isRating {
  color: #76767F;
}

.author-card-author-detail-rating {
  color: #1C1C1C;
}

.author-card-author-detail-review {
  color: #BCBCC2;
}

@media only screen and (min-width: 912px) {
  .author-card-author img {
    height: 50px;
    width: 50px;
  }

  .author-card-author-nama{
    font: var(--subHeading-4);
  }
  
  .author-card-author-detail-item {
    font: var(--subHeading-5);
  }
}