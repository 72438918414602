.kategori-item-detail {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font: var(--heading-8);
  font-weight: 500;
}

.kategori-item-svg {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  transition: fill 0.5s ease;
}

.kategori-item-svg:hover {
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .kategori-item-detail {
    font: var(--heading-6);
    font-weight: 500;
    width: 200px;
    margin-bottom: 30px;
    gap: 10px;
  }
  
  .kategori-item-svg {
    width: 75px;
    height: 75px;
  }
}