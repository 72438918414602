.home-container {
  display: flex;
  flex-direction: column;
	margin-top: 50px;
  overflow-x: hidden;
}

.home-item-top {
	display: flex;
	justify-content: center;
  margin: 0px auto;
  max-width: 1200px;
  width: 100%;
}

.home-item-top-item {
	display: flex;
	flex-direction: column;
	align-items: center;
  margin: 10px 15px;
}

.home-item-top-item h1, .home-item-top-item h4 {
	text-align: center;
}

.home-item-top-item h1 {
  font: var(--heading-5);
}

.home-item-top-item h4 {
	color: #9797A1;
  font: var(--paragraph-3);
	margin : 10px 0px;
}

.home-image {
  margin: 30px 0px;
	width: 200px;
}

.home-item-bottom {
	display: flex;
	flex-direction: row;
  margin-top: 30px;
  width: 100%;
	background-color: #E4E4E7;
}

.home-item-bottom-box {
  display: flex;
	flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
	justify-content: space-evenly;
	align-items: center;
	padding: 20px 15px;
  gap: 20px;
}

.home-item-bottom-detail {
	display: flex;
	justify-content: center;
	align-items: center;
  width: 100%;
  max-width: 180px;
	background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  padding: 5px;
}

.home-item-bottom-svg {
  height: 40px;
  width: 40px;
}

.home-item-bottom-content {
	display: flex;
	flex-direction: column;
  margin-left: 5px;
}

.home-item-bottom-content h1 {
  font: var(--heading-5);
}

.home-item-bottom-content label {
  color: #76767F;
  font: var(--subHeading-7);
}

@media only screen and (min-width: 320px) {
  .home-image {
    width: 250px;
  }

  .home-item-top-item h1 {
    font: var(--heading-5);
    max-width: 450px;
  }

  .home-item-top-item h4 {
    font: var(--paragraph-2);
    max-width: 450px;
  }
}

@media only screen and (min-width: 612px) {
  .home-image {
    width: 350px;
  }

  .home-item-top-item h1 {
    font-size: 30px;
  }

  .home-item-bottom-detail {
    max-width: 220px;
  }

  .home-item-bottom-svg {
    height: 45px;
    width: 45px;
  }

  .home-item-bottom-content h1 {
    font: var(--heading-5);
  }
  
  .home-item-bottom-content label {
    font: var(--subHeading-6);
  }
}

@media only screen and (min-width: 912px) {
  .home-container {
    margin-top: 70px;
  }

  .home-item-top {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 30px auto;
  }

  .home-item-top-item {
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    gap: 15px;
    margin: 0px 0px 0px 40px;
  }

  .home-item-top-item h1 {
    text-align: left;
    font: var(--heading-4);
    max-width: none;
  }

  .home-item-top-item h4 {
    text-align: left;
    font: var(--paragraph-1);
    max-width: none;
  }

  .home-image {
    margin: 0px 40px 0px 0px;
    flex: 1;
  }

  .home-item-bottom-box {
    padding: 20px 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .home-container {
    margin-bottom: 100px;
  }

  .home-item-top-item {
    margin: 0px;
  }

  .home-item-top-item h1 {
    font: var(--heading-3);
  }

  .home-image {
    margin: 0px;
  }

  .home-item-bottom-detail {
    max-width: 250px;
  }

  .home-item-bottom-box {
    padding: 20px 0px;
  }

  .home-item-bottom-svg {
    height: 50px;
    width: 50px;
  }

  .home-item-bottom-content h1 {
    font: var(--heading-4)
  }

  .home-item-bottom-content label {
    font: var(--subHeading-4)
  }
}