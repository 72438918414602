.daftarChat-detail {
  background-color: #fff;
}

.daftarChat-item {
  margin: 15px;
}

.daftarChat-item-search {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 35px;
}

.daftarChat-item-search input {
  border: none;
  outline: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  margin-left: 15px;
  font: var(--paragraph-4);
  font-weight: 500;
  color: #76767F;
}

.daftarChat-item-search-svg {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.daftarChat-item-filter {
  display: flex;
  padding: 10px 0px;
  justify-content: flex-end;
  border-bottom: 1px solid #E4E4E7;
}

.daftarChat-item-filter-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  font: var(--paragraph-4);
  font-weight: 500;
}

.daftarChat-item-filter-detail:hover {
  cursor: pointer;
}

.daftarChat-item-filter-svg {
  height: 16px;
  width: 16px;
}

.daftarChat-item-card {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

@media only screen and (min-width: 912px) {
  .daftarChat-detail {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    height: calc(100vh - 205px);
  }

  .daftarChat-item {
    flex: 1;
  }
  
  .daftarChat-item-card {
    overflow-y: auto;
    max-height: 615px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .dashboard-detail.daftarChat-detail-top {
    margin: 0px 40px 0px 40px;
  }
  .dashboard-wrap.daftarChat-wrap {
    flex-direction: column;
  }

  .dashboard-title.daftarChat-title {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .dashboard-detail.daftarChat-detail-top {
    margin: 0px;
  }
}