.sidebar-container {
  display: flex;
  position: fixed;
  max-width: 250px;
  width: 100vw;
  height: 100vh;
  top: 50px;
  left: -255px;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  border-top: 1px solid #ccc;
  transition: transform 0.3s ease-in-out;
}

.sidebar-container.open {
  transform: translateX(255px);
}

.sidebar-wrap {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 20px 15px;
  max-width: 1200px;
  width: 100%;
}

.sidebar-wrap-search {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 30px;
  background-color: #fff;
  margin-bottom: 15px;
}

.sidebar-input-search {
  border: none;
  outline: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  font-size: 12px;
}

.sidebar-logo-search {
  height: 25px;
  width: 25px;
  margin-right: 8px;
}

.sidebar-logo-search:hover {
  cursor: pointer;
}

.sidebar-dropdown {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.sidebar-dropdown:hover {
  cursor: pointer;
}

.sidebar-dropdown-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 15px;
  transition: all 1s ease;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  font: var(--subHeading-7);
  color: #55555E;
}

.sidebar-dropdown-detail.show {
  max-height: 500px;
  opacity: 1;
  visibility: visible;
  padding: 15px;
}

.sidebar-wrap-loginregister, .sidebar-wrap-footer, .sidebar-wrap-header{
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  padding-top: 15px;
  gap: 15px;
  text-align: center;
}

.sidebar-wrap-loginregister {
  gap: 10px;
  margin: 15px 0px;
}

.sidebar-wrap-header {
  gap: 0px;
  text-align: left;
}

.sidebar-button-register, .sidebar-button-login {
  display: flex;
  height: 30px;
  border-radius: 8px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
}

.sidebar-button-register:hover, .sidebar-button-login:hover {
  cursor: pointer;
}

.sidebar-button-register {
  background-color: var(--color);
  border: 1px solid #fff;
  color: #fff;
}

.sidebar-button-login {
  background-color: #fff;
  border: 1px solid var(--color);
  color: var(--color);
}

.sidebar-logo-register, .sidebar-logo-login {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.sidebar-wrap-footer-item, .sidebar-wrap-loginregister {
  font-size: 12px;
  color: black;
  font-weight: 500;
}

.sidebar-dropdown-detail-svg {
  height: 20px;
  width: 20px;
}

.sidebar-wrap-footer-item.dashboard {
  display: flex;
  align-items: center;
  gap: 5px;
}