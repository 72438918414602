.aktivitasCard-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #FAFAFA;
  border-radius: 8px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.aktivitasCard-wrap.ditolak {
  background-color: #F7E9EA;
}

.aktivitasCard-detail {
  display: flex;
  margin: 10px;
  padding: 3px;
  gap: 10px;
  width: calc(100% - 20px);
  border-radius: 8px;
}

.aktivitasCard-detail:hover {
  cursor: pointer;
  background-color: #E0E0E0;
}

.aktivitasCard-detail.ditolak:hover {
  background-color: #EFA3A3;
}

.aktivitasCard-detail img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #B0B0B0;
}

.aktivitasCard-detail-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.aktivitasCard-detail-item h4{
  font: var(--subHeading-5);
}

.aktivitasCard-detail-item h5{
  font: var(--subHeading-6);
  color: #76767F;
}

.aktivitasCard-detail-item p{
  font: var(--subHeading-8);
  color: #9797A1;
}

.aktivitasCard-button {
  display: flex;
  width: 100%;
  gap: 10px;
}

.aktivitasCard-svg {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

@media only screen and (min-width: 912px) {
  .aktivitasCard-wrap {
    align-items: flex-start;
  }

  .aktivitasCard-detail img {
    width: 40px;
    height: 40px;
  }

  .aktivitasCard-detail-item h4{
    font: var(--subHeading-4);
  }
  
  .aktivitasCard-detail-item h5{
    font: var(--subHeading-5);
  }
  
  .aktivitasCard-detail-item p{
    font: var(--subHeading-7);
  }
  
  .aktivitasCard-button {
    justify-content: flex-end;
    gap: 15px;
  }
  
  .aktivitasCard-svg {
    height: 24px;
    width: 24px;
  }
}