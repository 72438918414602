.lengkapiProfilFreelance-wrap {
  display: flex;
  width: 400vw;
  overflow-x: hidden;
  touch-action: pan-y;
}

.lengkapiProfilClient-wrap {
  display: flex;
  width: 100vw;
  overflow-x: hidden;
  touch-action: pan-y;
}

.lengkapiProfil-container {
  display: flex;
  margin-top: 115px;
  width: calc(100vw - 17px);
  transition: transform 1s ease-in-out;
}

.lengkapiProfil-wrap {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 10px 15px;
  max-width: 1200px;
  width: 100%;
}

.lengkapiProfil-title{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;

}

.lengkapiProfil-title label{
  font: var(--heading-6);
  margin-bottom: 5px;
}

.lengkapiProfil-title p, .lengkapiProfil-wrap-error {
  font: var(--subHeading-7);
}

.lengkapiProfil-wrap-error {
  text-align: center;
}

.lengkapiProfil-tambah-svg {
  height: 20px;
  width: 20px;
}

.lengkapiProfil-bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lengkapiProfil-bottom.noForm {
  margin-top: 15px;
}

@media only screen and (min-width: 912px) {
  .lengkapiProfil-container {
    margin-top: 185px;
  }

  .lengkapiProfil-wrap {
    padding: 30px 40px;
  }

  .lengkapiProfil-title label{
    font: var(--heading-5);
  }

  .lengkapiProfil-title p, .lengkapiProfil-wrap-error {
    font: var(--subHeading-6);
  }

  .lengkapiProfil-tambah-svg {
    height: 24px;
    width: 24px;
  }

  .lengkapiProfil-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    gap: 20px;
  } 
}

@media only screen and (min-width: 1200px) {
  .lengkapiProfil-wrap {
    margin: 30px auto;
    padding: 0px;
  }
}