.registerLogin-container {
  display: flex;
  margin-top: 50px;
}

.registerLogin-wrap {
  display: flex;
  margin: 0px auto;
  padding: 10px 15px;
  max-width: 1200px;
  width: 100%;
}

.registerLogin-wrap-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 10px;
}

.registerLogin-wrap-form h1 {
  font: var(--heading-6);
  color: #1c1c1c;
}

.registerLogin-wrap-form h5 {
  font: var(--paragraph-3);
  color: #9797a1;
  margin: 10px 0px;
}

.registerLogin-wrap-form-input-svg {
  height: 20px;
  width: 20px;
}

.registerLogin-wrap-form-input-svg:hover {
  cursor: pointer;
}

.registerLogin-wrap-button {
  display: flex;
  height: 40px;
}

.registerLogin-wrap-button input {
  border: none;
  width: 100%;
  background-color: #ab1f2a;
  border-radius: 6px;
  padding: 0px 15px;
  align-items: center;
  justify-content: center;
  font: var(--heading-7);
  font-weight: 500;
  border: 1px solid #e4e4e7;
  color: #fff;
}

.registerLogin-wrap-button div {
  display: flex;
  width: 100%;
  background-color: #ab1f2a;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e4e7;
}

.registerLogin-wrap-button input:hover {
  cursor: pointer;
}

.registerLogin-wrap-login {
  display: flex;
  font: var(--subHeading-5);
  justify-content: center;
}

.registerLogin-wrap-login label {
  color: #ab1f2a;
  font-weight: bold;
}

.registerLogin-wrap-login label:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .registerLogin-container {
    margin-top: 70px;
  }

  .registerLogin-wrap {
    padding: 30px 40px;
    gap: 20px;
  }

  .registerLogin-wrap-form {
    padding: 20px 0px;
    gap: 15px;
  }

  .registerLogin-wrap-form h1 {
    font: var(--heading-5);
  }

  .registerLogin-wrap-form h5 {
    font: var(--paragraph-2);
  }

  .registerLogin-wrap-image {
    display: flex;
    flex: 1;
    border-radius: 8px;
  }

  .registerLogin-wrap-image img {
    height: 100%;
    width: 100%;
    object-fit: "cover";
  }

  .registerLogin-wrap-form-input-svg {
    height: 24px;
    width: 24px;
    margin: 5px;
  }

  .registerLogin-wrap-button {
    height: 50px;
  }

  .registerLogin-wrap-button input {
    font: var(--heading-6);
    font-weight: 500;
  }

  .registerLogin-wrap-login {
    font: var(--subHeading-4);
  }
}

@media only screen and (min-width: 1200px) {
  .registerLogin-container {
    background-color: #f2f2f2;
  }

  .registerLogin-wrap {
    background-color: #fff;
    margin: 30px auto;
    padding: 0px;
    border-radius: 14px;
  }

  .registerLogin-wrap-form {
    padding-right: 20px;
  }
}
