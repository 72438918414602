.lihatJobs-container {
  display: flex;
  margin: 50px 0px 20px;
}

.lihatJobs-wrap {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  max-width: 1200px;
  width: 100%;
  gap: 10px;
}

.lihatJobs-wrap-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 15px;
}

.lihatJobs-navigasi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
}

.lihatJobs-navigasi-title {
  display: flex;
  padding: 15px 0px;
  border-radius: 8px 8px 0px 0px;
  justify-content: center;
  background-color: #1c1c1c;
  color: #fff;
  font: var(--heading-7);
}

.lihatJobs-navigasi-subTitle {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  background-color: #fff;
  color: #1c1c1c;
  border-bottom: 1px solid #e4e4e7;
  font: var(--subHeading-5);
}

.lihatJobs-navigasi-subTitle:last-child {
  border-bottom: none;
  border-radius: 0px 0px 8px 8px;
}

.lihatJobs-navigasi-subTitle:hover {
  cursor: pointer;
  background-color: #d3d3d3;
}

.lihatJobs-navigasi-subTitle.clicked {
  background-color: #b81f24;
  color: #fff;
}

.lihatJobs-subNavigasi-scroll {
  background-color: #fff;
  border-radius: 8px;
}

.lihatJobs-wrap-detail {
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
  gap: 10px;
}

.lihatJobs-filter,
.lihatJobs-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.lihatJobs-filter-svg {
  height: 25px;
  width: 25px;
}

.lihatJobs-filter-svg:hover {
  cursor: pointer;
}

.lihatJobs-filter-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lihatJobs-filter-option-select {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lihatJobs-filter-option-svg {
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-top: 3px;
}

.lihatJobs-filter-option-svg:hover {
  cursor: pointer;
}

.lihatJobs-card {
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.lihatJobs-card-line {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.lihatJobs-nothing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: transparent;
}

.lihatJobs-nothing-card-label {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
}

.lihatJobs-nothing-card label {
  font: var(--heading-5);
  color: #1c1c1c;
}

.lihatJobs-nothing-card p {
  font: var(--paragraph-3);
  color: #bcbcc2;
  text-align: center;
}

.lihatJobs-page {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.lihatJobs-page-svg:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .lihatJobs-container {
    margin-top: 70px;
  }

  .lihatJobs-wrap {
    gap: 20px;
    flex-direction: row;
  }

  .lihatJobs-wrap-bar {
    margin: 30px 0px 30px 40px;
    flex: 1;
  }

  .lihatJobs-navigasi-title {
    font: var(--heading-6);
  }

  .lihatJobs-navigasi-subTitle {
    font: var(--subHeading-4);
  }

  .lihatJobs-subNavigasi {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
  }

  .lihatJobs-subNavigasi-item {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px;
    max-width: 120px;
    text-align: center;
    align-items: center;
    font: var(--subHeading-5);
  }

  .lihatJobs-subNavigasi-item:hover {
    cursor: pointer;
    background-color: #d3d3d3;
  }

  .lihatJobs-subNavigasi-item.clicked {
    background-color: #808080;
  }

  .lihatJobs-filter-svg {
    height: 30px;
    width: 30px;
  }

  .lihatJobs-filter-option-svg {
    width: 30px;
    height: 30px;
  }

  .lihatJobs-wrap-detail {
    margin: 30px 40px 30px 0px;
    flex: 3;
  }

  .lihatJobs-nothing-card img {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) {
  .lihatJobs-wrap-bar {
    margin: 30px 0px;
  }

  .lihatJobs-wrap-detail {
    margin: 30px 0px;
  }
}
