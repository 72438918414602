.checkJobs-container {
    display: flex;
    margin-top: 50px;
    overflow-x: hidden;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  
  .checkJobs-wrap {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding: 10px 15px;
    width: 100%;
  }
  
  .checkJobs-top, .checkJobs-center , .checkJobs-bottom  {
    display: flex;
    background-color: #fff;
    width: 100%;
  }
  
  .checkJobs-top {
    border-radius: 8px 8px 0px 0px;
  }
  
  .checkJobs-center {
    border-bottom: 1px solid #ccc;
  }
  
  .checkJobs-center-wrap, .checkJobs-bottom-wrap {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    gap: 10px;
    width: 100%;
  }
  
  .checkJobs-center-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-width: 900px;
    overflow-wrap: break-word;
    width: 100%;
  }
  
  .checkJobs-center-item h3 {
    font: var(--subHeading-5);
    font-weight: bold;
  }
  
  .checkJobs-center-item p {
    font: var(--paragraph-4);
    white-space: normal;
  }
  
  .checkJobs-bottom {
    border-radius: 0px 0px 8px 8px;
  }
  
  .checkJobs-bottom-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .checkJobs-bottom-price label {
    font: var(--subHeading-3);
  }
  
  .checkJobs-addons {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  
  .checkJobs-addons-bar {
    display: flex;
    gap: 30px;
    font: var(--subHeading-4);
    color: #BCBCC2;
  }
  
  .checkJobs-addons-pelamar, .checkJobs-addons-direkrut {
    padding: 5px;
  }
  
  .checkJobs-addons-pelamar:hover, .checkJobs-addons-direkrut:hover {
    cursor: pointer;
  }
  
  .checkJobs-addons-pelamar.clicked, .checkJobs-addons-direkrut.clicked {
    color: #1C1C1C;
    border-bottom: 1px solid #BCBCC2;
  }
  
  @media only screen and (min-width: 912px) {
    .checkJobs-container {
      margin-top: 70px;
    }
  
    .checkJobs-wrap {
      padding: 30px 40px;
    }
  
    .checkJobs-center-wrap {
      padding: 20px;
      gap: 20px;
    }
  
    .checkJobs-center-item h3 {
      font: var(--subHeading-3);
      font-weight: bold;
    }
    
    .checkJobs-center-item p, .checkJobs-top-detail-bottom-item p {
      font: var(--paragraph-2);
      white-space: pre-line;
    }
  
    .checkJobs-top-detail-bottom {
      display: flex;
      gap: 40px; 
    }
  
    .checkJobs-top-detail-bottom-item {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    
    .checkJobs-bottom-wrap {
      padding: 20px;
      flex-direction: row;
      justify-content: space-between;
    }
  
    .checkJobs-bottom-price {
      flex-direction: row;
      gap: 20px;
    }
    
    .checkJobs-bottom-price label {
      font: var(--subHeading-2);
    }
    
    .checkJobs-addons-bar {
      font: var(--subHeading-3);
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .checkJobs-wrap {
      margin: 30px auto;
      padding: 0px;
    }
  }