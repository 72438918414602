.squaredFour {
  width: 18px;
  position: relative;    
  display: flex;
}

.squaredFour label {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  background: #fcfff4;
  background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
}

.squaredFour label::after {
  content: '';
  width: 7px;
  height: 4px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
  cursor: pointer;
}

.squaredFour.disabled label::after {
  border-color: #B4B4B7;
}

.squaredFour label:hover::after{
  opacity: 0.5;
}

.squaredFour input[type=checkbox] {
  visibility: hidden;
}

.squaredFour input[type=checkbox]:checked + label::after{
  opacity: 1;
}

@media only screen and (min-width: 912px) {
  .squaredFour {
    width: 20px; 
  }
  
  .squaredFour label {
    width: 20px;
    height: 20px;
  }
  
  .squaredFour label::after {
    width: 9px;
    height: 5px;
    top: 4px;
    left: 4px;
  }
}