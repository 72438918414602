.proyekCard-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.proyekCard-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.proyekCard-status {
  display: flex;
  border-radius: 14px;
  padding: 5px;
  justify-content: center;
  font: var(--subHeading-6);
  width: 100%;
}

.proyekCard-nama {
  font: var(--heading-7);
}

.proyekCard-kategori {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E4E4E7;
}

.proyekCard-kategori-detail {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  background-color: #E6F8F8;
  color: #AB1F2A;
  border-radius: 12px;
  font: var(--subHeading-6);
}

@media only screen and (min-width: 912px) {
  .proyekCard-wrap {
    align-items: flex-start;
  }

  .proyekCard-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }

  .proyekCard-status {
    font: var(--subHeading-5);
    padding: 5px 25px;
    width: auto;
  }

  .proyekCard-nama {
    font: var(--heading-6);
  }

  .proyekCard-kategori-detail {
    font: var(--subHeading-5);
  }
}