.daftar-wrap-form-syaratketentuan {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.daftar-wrap-form-syaratketentuan-label {
  display: flex;
  font: var(--subHeading-5);
  text-align: justify;
}

@media only screen and (min-width: 912px) {
  .daftar-wrap-form-syaratketentuan-label {
    font: var(--subHeading-4);
  }
}