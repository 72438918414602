.chatCard-wrap {
  display: flex;
  border-radius: 8px;
  width: 100%;
  margin: 0;
}

.chatCard-wrap:hover {
  cursor: pointer;
  background-color: #E4E4E7;
}

.chatCard-detail {
  display: flex;
  margin: 5px;
  gap: 10px;
  width: 100%;
}

.chatCard-detail img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #B0B0B0;
}

.chatCard-detail-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin: 0;
}

.chatCard-detail-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatCard-detail-item h4 {
  font: var(--subHeading-6);
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatCard-detail-item h5 {
  font: var(--subHeading-7);
  color: #BCBCC2;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatCard-detail-item p {
  font: var(--subHeading-8);
  color: #76767F;
}