.navbarIndikator-container {
  display: flex;
  position: fixed;
  width: 100vw;
  top: 50px;
  z-index: 2;
  background-color: #fff
}

.navbarIndikator-wrap {
  display: flex;
  margin: 0px auto;
  padding: 0px 15px;
  max-width: 1200px;
  width: 100%;
}

.navbarIndikator-item {
  display: flex;
  width: 100%;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-image: linear-gradient(to right, #E4E4E7 50%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 20px 2px;
  background-repeat: repeat-x;
}

.navbarIndikator-circle {
  width: 25px;
  height: 25px;
  font: var(--subHeading-5);
  line-height: 25px;
  border: 2px solid #E4E4E7;
  border-radius: 50%;
  color: #BCBCC2;
  text-align: center;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, #fff 50%, #AB1F2A 50%);
  transition: all 1s;
}

@media only screen and (min-width: 320px) {
  .navbarIndikator-item {
    gap: 20px;
  }
}

@media only screen and (min-width: 912px) {
  .navbarIndikator-container {
    top: 70px;
  }

  .navbarIndikator-wrap {
    padding: 30px 40px;
  }

  .navbarIndikator-circle {
    width: 30px;
    height: 30px;
    font: var(--subHeading-4);
    line-height: 30px;
  }

  .navbarIndikator-item-label {
    font: var(--subHeading-4);
    color: #BCBCC2;
    transition: all 1s;
  }

  .navbarIndikator-item-gt {
    color: #D9D9D9;
    font-size: 32px;
  }
}

@media only screen and (min-width: 1200px) {
  .navbarIndikator-wrap {
    padding: 0px;
    margin: 30px auto;
  }
}