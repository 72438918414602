.custom-input-button {
  display: flex;
  height: 35px;
  width: 100%;
  border-radius: 6px;
	padding: 0px;
	align-items: center;
	justify-content: center;
	font: var(--heading-8);
  font-weight: 500;
}

.custom-input-button.transparant {
  background-color: #fff;
	border: 1px solid var(--color);
	color: var(--color);
}

.custom-input-button.color {
  background-color: var(--color);
  border: 1px solid #E4E4E7;
	color: #fff;
}

.custom-input-button:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .custom-input-button {
    font: var(--heading-7);
    font-weight: 500;
    height: 40px;
    width: 200px;
  }
}