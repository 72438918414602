.detailJobs-top-container {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  gap: 5px;
  border-collapse: collapse;
  border-spacing: 10px;
}

.detailJobs-top-wrap {
  width: 100%;
  height: 100%;
}

.detailJobs-top-top-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.detailJobs-top-top-detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  height: 100%;
}

.detailJobs-top-top-detail-kategori, .detailJobs-top-top-detail-status {
  display: flex;
  align-items: center;
  font: var(--subHeading-7);
  background-color: #D7D7DB;
  border-radius: 8px;
  padding: 1px 15px;
  text-align: center;
}

.detailJobs-top-top-detail-title {
  font: var(--heading-8);
  line-height: 140%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  max-width: 1075px;
}

.detailJobs-top-middle-detail {
  font-size: 8px;
  padding: 5px 0px;

}

.detailJobs-top-bottom-detail {
  display: flex;
  justify-content: space-between;
}

.detailJobs-top-bottom-detail-svg {
  height: 14px;
  width: 14px;
}

.detailJobs-top-bottom-detail-item {
  display: flex;
  align-items: center;
  font: var(--subHeading-8);
  gap: 2px;
}

@media only screen and (min-width: 912px) {
  .detailJobs-top-container {
    padding: 20px;
  }

  .detailJobs-top-top-detail-title {
    font: var(--heading-6);
  }

  .detailJobs-top-top-detail-status {
    font: var(--subHeading-6)
  }

  .detailJobs-top-top-detail-kategori {
    font: var(--subHeading-5);
  }

  .detailJobs-top-top-detail-kategori{
    display: inline-block;
    margin: 5px 0px;
  }

  .detailJobs-top-bottom-detail-svg {
    height: 18px;
    width: 18px;
  }
  
  .detailJobs-top-bottom-detail-item {
    font: var(--subHeading-6);
    gap: 5px;
    padding: 1px 0px;
    margin-top: 5px;
  } 
}