.portofolioCard-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border-bottom: 1.5px solid #E4E4E7;
}

.portofolioCard-view {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: 1px solid #BCBCC2;
  border-radius: 8px;
}

.portofolioCard-view img {
  max-height: 200px;
  max-width: 200px;
  width: 100%;
}

.portofolioCard-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  font: var(--paragraph-3);
}

.portofolioCard-svg-wrap {
  display: flex;
  gap: 15px;
}

.portofolioCard-svg {
  height: 22px;
  width: 22px;
}

.portofolioCard-svg:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .portofolioCard-label {
    font: var(--paragraph-2);
  }
}