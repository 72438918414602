.horizontal-scroll-container {
  overflow-x: hidden;
  white-space: nowrap;
  cursor: grab;
  display: flex;
  width: calc(100% - 30px);
  padding: 0px 15px;
  user-select: none;
}

.horizontal-scroll-container.card {
  padding: 0px;
}

.horizontal-scroll-container:active {
  cursor: grabbing;
}

.horizontal-scroll-wrap {
  display: flex;
  margin: 20px 0px;
  gap: 15px;
}

.kategori-scroll-item {
  display: flex;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font: var(--heading-8);
  transition: background-color 0.5s ease;
}

.kategori-scroll-item:hover {
  cursor: pointer;
  background-color: #AB1F2A;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  color: #fff;
}

@media only screen and (min-width: 912px) {
  .horizontal-scroll-container {
    width: calc(100% - 80px);
    padding: 0px 40px;
  }

  .horizontal-scroll-container.card {
    padding: 0px;
  }

  .horizontal-scroll-wrap {
    gap: 30px;
    margin: 50px 0px;
  }

  .horizontal-scroll-wrap.card {
    margin: 30px 0px;
  }

  .kategori-scroll-item {
    font: var(--heading-6);
    padding: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .horizontal-scroll-container {
    width: 100%;
    padding: 0px;
  }
}