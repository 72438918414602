.rekomendasi-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  overflow-x: hidden;
}

.rekomendasi-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  margin: 0px auto;
  max-width: 1200px;
  width: 100%;
}

.rekomendasi-wrap h3 {
  margin: 30px 0px;
  font: var(--label-3);
  letter-spacing: 5px;
  color: #b81f24;
}

.rekomendasi-card-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.kategori-wrap h3 {
  color: "#b81f24";
}

@media only screen and (min-width: 320px) {
  .rekomendasi-wrap h3 {
    letter-spacing: 10px;
  }
}

@media only screen and (min-width: 912px) {
  .rekomendasi-container {
    margin-bottom: 100px;
  }

  .rekomendasi-wrap {
    padding: 0px 40px;
  }

  .rekomendasi-wrap h3 {
    margin: 80px 0px 50px;
    font: var(--label-1);
  }

  .rekomendasi-card-container {
    gap: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .rekomendasi-wrap {
    padding: 0px;
  }
}
