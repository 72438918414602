.portofolioModal-container {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: transparent;
  backdrop-filter: blur(5px);
  z-index: 3;
  display: flex;
  justify-content: center;
}

.portofolioModal-wrap {
  background-color: #fff;
  width: 80%;
  max-width: 1100px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  max-height: 80vh;
}

.portofolioModal-item {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  gap: 15px;
}

.portofolioModal-bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 912px) {
  .portofolioModal-container {
    top: 50px;
  }

  .portofolioModal-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    gap: 20px;
  }
}