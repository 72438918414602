.pengaturan-detail.notifikasi-wrap {
  gap: 30px;
}

.notifikasi-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
}

.notifikasi-item label {
  font: var(--subHeading-5);
  font-weight: bold;
}

@media only screen and (min-width: 912px) {
  .notifikasi-item label {
    font: var(--subHeading-4);
    font-weight: bold;
  }
}