.dashboard-title.kelola-moderator-title {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.kelola-moderator-title button, .proyekSaya-extend-link{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: none;
  background-color: #AB1F2A;
  color: #fff;
  padding: 7px;
  border-radius: 6px;
  width: 175px;
  font: var(--subHeading-6);
}

.kelola-moderator-title button:hover {
  cursor: pointer;
}

@media only screen and (min-width: 912px) {
  .kelola-moderator-title button, .proyekSaya-extend-link{
    font: var(--subHeading-5);
  }
}