.portofolio-warning {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #FFF8B5;
  padding: 12px;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
}

.portofolio-warning-svg {
  height: 30px;
  width: 30px;
}

.portofolio-warning label {
  font: var(--subHeading-4);
  color: #AD1F2A;
}

.portofolio-warning p {
  text-align: center;
  font: var(--subHeading-5);
  color: #1C1C1C;
}

@media only screen and (min-width: 912px) {
  .portofolio-warning label {
    font: var(--subHeading-3);
  }
  
  .portofolio-warning p {
    text-align: center;
    font: var(--subHeading-4);
    color: #1C1C1C;
  }

  .portofolio-warning-svg {
    height: 35px;
    width: 35px;
  }

  .portofolio-portofolioCard-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}