.lihatJobs-card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 100%;
  height: 120px;
  gap: 5px;
  border-radius: 8px;
}

.lihatJobs-card-wrap:hover {
  cursor: pointer;
  background-color: #ccc;
}

.lihatJobs-card-svg {
  height: 40px;
  width: 40px;
  border-radius: 8px;
}

.lihatJobs-card-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}

.lihatJobs-card-detail-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.lihatJobs-card-detail-title {
  display: flex;
  justify-content: space-between;
}

.lihatJobs-card-detail-title-judul {
  font: var(--heading-8);
  line-height: 140%;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
  text-align: justify;
}

.lihatJobs-card-detail-title-icon {
  font-size: 10px;
  color: #faec1b;
  text-shadow: 0 0 2px #ffffff, 0 0 10px #ffee58;
}

.lihatJobs-card-detail-subtitle {
  display: flex;
  font: var(--subHeading-7);
  gap: 5px;
}

.lihatJobs-card-detail-subtitle-kategori {
  font: var(--subHeading-7);
  background-color: #D7D7DB;
  border-radius: 12px;
  padding: 0px 5px;
  margin-right: 5px;
}

.lihatJobs-card-detail-subtitle-svg {
  height: 12px;
  width: 12px;
  margin: auto 0px;
}

.lihatJobs-card-detail-bottom {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
  justify-content: space-between;
}

.lihatJobs-card-detail-bottom-item {
  display: flex;
  align-items: center;
  font: var(--subHeading-7);
  gap: 2px;
}

@media only screen and (min-width: 912px) {
  .lihatJobs-card-wrap {
    flex-direction: row;
    height: 120px;
    justify-content: space-between;
    padding: 20px;
  }
  
  .lihatJobs-card-svg {
    height: 70px;
    width: 70px;
  }

  .lihatJobs-card-detail {
    width: 100%;
  }

  .lihatJobs-card-detail-item {
    height: 70px;
    gap: 0px;
  }

  .lihatJobs-card-detail-title-judul {
    font: var(--heading-6);
    max-height: 40px;
  }

  .lihatJobs-card-detail-title-icon {
    font-size: 16px;
  }
  
  .lihatJobs-card-detail-subtitle {
    font: var(--subHeading-4);
  }

  .lihatJobs-card-detail-subtitle-kategori {
    font: var(--subHeading-4);
  }

  .lihatJobs-card-detail-subtitle-svg {
    height: 16px;
    width: 16px;
  }

  .lihatJobs-card-detail-bottom {
    margin-left: -70px;
  }
  
  .lihatJobs-card-detail-bottom-item {
    font: var(--subHeading-4);
  }
}