.profilSaya-edit {
  display: flex;
  justify-content: right;
}

.profilSaya-edit-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  font: var(--subHeading-6);
  color: #76767F;
}

.profilSaya-edit-wrap:hover {
  cursor: pointer;
}

.profilSaya-edit-svg {
  height: 24px;
  width: 24px;
}