.dashboardPage-container {
  display: flex;
  flex-direction: column;
  background-color: #F3F3F3;
  justify-content: space-between;
  min-height: 100vh;
}

.dashboard-container {
  display: flex;
  margin-top: 50px;
}

.dashboard-wrap {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 10px 0px;
  max-width: 1200px;
  width: 100%;
}

.dashboard-detail {
  margin: 0px 15px;
}

.dashboard-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.dashboard-title h3 {
  font: var(--heading-7);
}

.dashboard-title p {
  font: var(--paragraph-4);
  color: #76767F;
}

.dashboard-title h4 {
  font: var(--heading-8);
}

.dashboard-item, .dashboard-table {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  gap: 10px;
}

.dashboard-table {
  padding: 0px;
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.dashboard-table-head {
  font: var(--heading-9);
  background-color: #E6F8F8;
}

.dashboard-table-body {
  font: var(--subHeading-6)
}

.dashboard-table-head-detail, .dashboard-table-body-detail {
  text-align: center;
  word-wrap: break-word;
  padding: 5px;
  max-width: 150px;
  border: 1px solid #E4E4E7;
}

.dashboard-table-head-detail:first-child {
  border-top-left-radius: 8px;
}

.dashboard-table-head-detail:last-child {
  border-top-right-radius: 8px;
}

.dashboard-table-body-detail-action {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dashboard-tab {
  display: flex;
  margin: 10px 0px;
}

.dashboard-tab-item {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #E4E4E7;
  font: var(--subHeading-5);
}

.dashboard-tab-item:hover {
  cursor: pointer;
  background-color: #E4E4E7;
}

.dashboard-tab-item.clicked {
  color: #AB1F2A;
  border-bottom: 1px solid #AB1F2A;
}

@media only screen and (min-width: 912px) {
  .dashboard-container {
    margin-top: 70px;
  }

  .dashboard-wrap {
    flex-direction: row;
    padding: 30px 0px;
    gap: 30px;
  }

  .dashboard-bar {
    margin: 0px 0px 0px 40px;
    display: flex;
    flex: 1;
  }

  .dashboard-title h3 {
    font: var(--heading-6);
  }
  
  .dashboard-title p {
    font: var(--paragraph-3);
  }
  
  .dashboard-title h4 {
    font: var(--heading-7);
  }

  .dashboard-detail {
    margin: 0px 40px 0px 0px;
    flex: 3;
  }

  .dashboard-table-head {
    font: var(--heading-8);
  }

  .dashboard-table-head-detail, .dashboard-table-body-detail {
    padding: 10px;
  }

  .dashboard-table-body {
    font: var(--subHeading-5)
  }

  .dashboard-table-body-detail-action {
    gap: 15px;
  }

  .dashboard-tab-item {
    position: relative;
    font: var(--subHeading-4);
    flex: initial;
    z-index: 3;
  }

  .dashboard-tab-item-line {
    position: relative;
    left: 0;
    right: 0;
    top: -11px;
    height: 1px;
    z-index: 2;
    background-color: #E4E4E7;
  }

  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .dashboard-wrap {
    margin: 30px auto;
    padding: 0px;
  }

  .dashboard-bar {
    margin: 0px;
  }

  .dashboard-detail {
    margin: 0px;
  }
}