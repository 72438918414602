.previewJob-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #E4E4E7;
  border-radius: 12px;
  overflow: hidden; /* Ensure content does not overflow */
}

.previewJob-detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words */
  overflow: hidden; /* Prevent content from overflowing */
}

.previewJob-detail h4, .previewJob-detail h5 {
  font: var(--subHeading-5);
  overflow-wrap: break-word; /* Break long words in headings */
}

.previewJob-detail h4 {
  font-weight: bold;
}

.previewJob-detail h5 a {
  text-decoration: underline;
}

@media only screen and (min-width: 912px) {
  .previewJob-item {
    gap: 20px;
    padding: 20px;
  }

  .previewJob-detail {
    gap: 10px;
  }

  .previewJob-detail h4, .previewJob-detail h5 {
    font: var(--subHeading-4);
  }

  .previewJob-detail h4 {
    font-weight: bold;
  }
}
